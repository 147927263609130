<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="運搬事業者登録" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>
        <!-- 企業情報 ======================================================================== -->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">企業情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>企業名</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="l-area">
                  <Selectbox
                    name="legalPersonality"
                    :value.sync="form.legalPersonalityTypeId"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.companyInfo.legalPersonalityTypeId.isValid"
                    :errMsg.sync="validate.companyInfo.legalPersonalityTypeId.errMsg"
                  >
                    <option
                      v-for="legalPersonality in legalPersonalityTypes"
                      :key="legalPersonality.id"
                      :value="legalPersonality.id"
                    >
                      {{ legalPersonality.name }}
                    </option>
                  </Selectbox>
                </div>
                <InputText
                  :value.sync="form.companyName"
                  inputType="text"
                  placeholder="企業名"
                  tooltip="100文字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="100"
                  :isValid.sync="validate.companyInfo.companyName.isValid"
                  :errMsg.sync="validate.companyInfo.companyName.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>住所</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>郵便番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap__items__cols center sm-mgn">
                      <InputText
                        :value.sync="form.addressInfo.zipCode"
                        inputType="number"
                        placeholder="1234567(ハイフンなし)"
                        tooltip="7桁の半角数字で入力してください。"
                        :validation="validateNumberEqualLen"
                        :validateArg="7"
                        :isValid.sync="validate.companyInfo.zipcode.isValid"
                        :errMsg.sync="validate.companyInfo.zipcode.errMsg"
                      />
                      <div
                        class="c-btn secondary sm"
                        @click="searchAddressHandler(form.addressInfo)"
                      >
                        住所自動入力
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>都道府県</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <Selectbox
                        name="prefecture"
                        :value.sync="form.addressInfo.selectedPrefectureTemp"
                        :validation="validateJustBlank"
                        :isValid.sync="validate.companyInfo.prefecture.isValid"
                        :errMsg.sync="validate.companyInfo.prefecture.errMsg"
                      >
                        <option
                          v-for="prefecture in prefectures"
                          :key="prefecture.id"
                          :value="prefecture.name"
                        >
                          {{ prefecture.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>市町村</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="form.addressInfo.address"
                        inputType="text"
                        placeholder="市町村"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="validate.companyInfo.address.isValid"
                        :errMsg.sync="validate.companyInfo.address.errMsg"
                      />
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>番地建物名号室</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="form.addressInfo.buildingName"
                        inputType="text"
                        placeholder="番地建物名号室"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="validate.companyInfo.building.isValid"
                        :errMsg.sync="validate.companyInfo.building.errMsg"
                      />
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.tel"
                  inputType="number"
                  placeholder="電話番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.companyInfo.tel.isValid"
                  :errMsg.sync="validate.companyInfo.tel.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>FAX番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.fax"
                  inputType="number"
                  placeholder="FAX番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.companyInfo.fax.isValid"
                  :errMsg.sync="validate.companyInfo.fax.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>担当者名</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.staffName"
                  inputType="text"
                  placeholder="担当者名"
                  tooltip="50文字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="50"
                  :isValid.sync="validate.companyInfo.staffName.isValid"
                  :errMsg.sync="validate.companyInfo.staffName.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>担当者メールアドレス</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.staffMail"
                  inputType="text"
                  placeholder="担当者名メールアドレス"
                  tooltip="メールアドレスを入力してください"
                  :validation="validateMail"
                  :isValid.sync="validate.companyInfo.staffMail.isValid"
                  :errMsg.sync="validate.companyInfo.staffMail.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業者コード</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.businessCode"
                    inputType="text"
                    placeholder="事業者コード"
                    tooltip="半角6文字で入力してください"
                    :validation="validateHalfSizeTextEqualLen"
                    :validateArg="6"
                    :isValid.sync="validate.companyInfo.businessCode.isValid"
                    :errMsg.sync="validate.companyInfo.businessCode.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>許可証情報</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <div class="c-radio">
                    <input
                        id="is-licence"
                        type="radio"
                        :value="true"
                        v-model="form.isLicence"
                    />
                    <label class="c-radio__label" for="is-licence">
                      <span class="c-radio__box"></span>
                      有り
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                        id="is-not-licence"
                        type="radio"
                        :value="false"
                        v-model="form.isLicence"
                    />
                    <label class="c-radio__label" for="is-not-licence">
                      <span class="c-radio__box"></span>
                      無し
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <!-- 許可証情報 ======================================================================== -->
        <template v-if="form.isLicence">
          <div
              v-for="(licence, licenceIndex) in form.collectLicenceCreateList"
              :key="'licence-' + licenceIndex"
              class="l-contents l-each"
          >
            <div class="l-box u-space-btwn">
              <h3 class="c-lead icon">許可証{{ licenceIndex + 1 }}</h3>
              <div class="c-btn secondary sm delete"
                  @click="clickDeleteLicenceButton(licenceIndex)"
              >
                削除
              </div>
            </div>
            <div class="l-box">

              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>廃棄物区分</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols l-area">
                    <div class="c-radio" v-for="wt in wasteTypeList" :key="wt.id">
                      <input
                        :id="'waste-type-' + wt.id + '-licence-' + licenceIndex"
                        type="radio"
                        :name="`waste-type-${licenceIndex}`"
                        :value="wt.id"
                        v-model="licence.wasteTypeId"
                      />
                      <label class="c-radio__label" :for="'waste-type-' + wt.id + '-licence-' + licenceIndex">
                        <span class="c-radio__box"></span>
                        {{ wt.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>許可番号</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputText
                      :value.sync="licence.licenceNo"
                      :inputType="licence.wasteTypeId === wasteType.INDUSTRIAL_WASTE ? 'number' : 'text'"
                      placeholder="許可番号"
                      :tooltip="licence.wasteTypeId === wasteType.INDUSTRIAL_WASTE ? '最大11文字の半角数値で入力してください' : '最大50文字で入力してください'"
                      :validation="licence.wasteTypeId === wasteType.INDUSTRIAL_WASTE ? validateNumberMaxLen : validateMaxLen"
                      :validateArg="licence.wasteTypeId === wasteType.INDUSTRIAL_WASTE ? 11 : 50"
                      :blur="() => licenceNoBlurHandler(licenceIndex)"
                      :isValid.sync="validate.collectLicence[licenceIndex].licenceNo.isValid"
                      :errMsg.sync="validate.collectLicence[licenceIndex].licenceNo.errMsg"
                  />
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>許可証期限</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputDate
                      :value.sync="licence.licenceExpiredDate"
                      placeholder="選択してください"
                      :validation="validateJustBlank"
                      :isValid.sync="validate.collectLicence[licenceIndex].licenceExpiredDate.isValid"
                      :errMsg.sync="validate.collectLicence[licenceIndex].licenceExpiredDate.errMsg"
                  />
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>優良認定</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols">
                    <div class="c-radio">
                      <input
                          :id="'excellent-' + licenceIndex"
                          type="radio"
                          :value="true"
                          v-model="licence.isExcellent"
                      />
                      <label class="c-radio__label" :for="'excellent-' + licenceIndex">
                        <span class="c-radio__box"></span>
                        有り
                      </label>
                    </div>
                    <div class="c-radio">
                      <input
                          :id="'not-excellent-' + licenceIndex"
                          type="radio"
                          :value="false"
                          v-model="licence.isExcellent"
                      />
                      <label class="c-radio__label" :for="'not-excellent-' + licenceIndex">
                        <span class="c-radio__box"></span>
                        無し
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-inputWrap" v-show="licence.wasteTypeId === 2">
                <div class="c-inputWrap__label required">
                  <label>市区町村</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="l-area">
                    <InputText
                      :value.sync="licence.commonCityName"
                      inputType="text"
                      placeholder="市区町村"
                      tooltip="100文字以内で入力してください"
                      :validateArg="100"
                      :validation="validateMaxLen"
                      :isValid.sync="validate.collectLicence[licenceIndex].commonCityName.isValid"
                      :errMsg.sync="validate.collectLicence[licenceIndex].commonCityName.errMsg"
                    />
                  </div>
                </div>
              </div>

              <div class="c-inputWrap" v-show="licence.wasteTypeId === 1">
                <div class="c-inputWrap__label">
                  <label>自治体名</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ municipalityList.filter(m => m.licenceNo === licence.licenceNo).length ? municipalityList.filter(m => m.licenceNo === licence.licenceNo)[0].municipality : '' }}
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>許可証PDF</label>
                </div>
                <div class="c-inputWrap__items">
                  <input
                      :id="'pdf-' + licenceIndex"
                      type="file"
                      accept="application/pdf"
                      @change="(e) => uploadPdf(e, licenceIndex)"
                  />
                  <label class="c-btn secondary upload sm l-item" :for="'pdf-' + licenceIndex">
                    PDFアップロード
                  </label>
                  <div class="c-inputWrap__items__cols">
                    {{ licence.licenceFileName }}
                  </div>
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>対象廃棄物種類</label>
                </div>
                <div class="c-inputWrap__items">
                  <div
                      class="c-btn secondary func add l-item"
                      @click="showJwnetItemsModalHandler(licenceIndex)"
                  >
                    対象廃棄物種類設定
                  </div>
                  <div class="c-inputWrap__items__cols">
                    {{ licence.targetJwnetItemList.map(item => item.name).join("、") }}
                  </div>
                  <p v-if="validate.collectLicence[licenceIndex].targetJwnetItemList.errMsg" class="c-input__errMsg">
                    {{ validate.collectLicence[licenceIndex].targetJwnetItemList.errMsg }}
                  </p>
                </div>
              </div>

              <div class="c-inputWrap" v-show="licence.wasteTypeId === 1">
                <div class="c-inputWrap__label required">
                  <label>積替保管情報</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols l-area">
                    <div class="c-radio">
                      <input
                          :id="'transshipment-' + licenceIndex"
                          type="radio"
                          :value="true"
                          v-model="licence.isTransshipment"
                      />
                      <label
                          class="c-radio__label"
                          :for="'transshipment-' + licenceIndex"
                      >
                        <span class="c-radio__box"></span>
                        積替保管有り
                      </label>
                    </div>
                    <div class="c-radio">
                      <input
                          :id="'not-transshipment-' + licenceIndex"
                          type="radio"
                          :value="false"
                          v-model="licence.isTransshipment"
                      />
                      <label
                          class="c-radio__label"
                          :for="'not-transshipment-' + licenceIndex"
                      >
                        <span class="c-radio__box"></span>
                        積替保管無し
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 積替保管場所 -->
            <template v-if="licence.isTransshipment && (licence.wasteTypeId == wasteType.INDUSTRIAL_WASTE)">
              <div class="l-box" v-for="(transshipment, transshipmentIndex) in licence.transshipmentCreateList"
                   :key="'transshipment-' + transshipmentIndex">
                <div class="l-box u-space-btwn">
                  <h3 class="c-lead icon">
                    積替保管場所{{ transshipmentIndex + 1 }}
                  </h3>
                  <div
                      v-if="licence.transshipmentCreateList.length > 1"
                      class="c-btn secondary sm delete"
                      @click="clickDeleteTransshipmentButton(licenceIndex, transshipmentIndex)"
                  >
                    削除
                  </div>
                </div>
                <div class="l-block-lg">
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label required">
                      <label>積替保管場所名</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <InputText
                          :value.sync="transshipment.name"
                          inputType="text"
                          placeholder="積替保管場所名"
                          tooltip="50文字以内で入力してください"
                          :validation="validateMaxLen"
                          :validateArg="50"
                          :isValid.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].name.isValid
                      "
                          :errMsg.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].name.errMsg
                      "
                      />
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label required">
                      <label>住所</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div class="c-inputWrap">
                        <div class="c-inputWrap__label">
                          <label>郵便番号</label>
                        </div>
                        <div class="c-inputWrap__items">
                          <div class="c-inputWrap__items__cols center sm-mgn">
                            <InputText
                                :value.sync="transshipment.addressInfo.zipCode"
                                inputType="number"
                                placeholder="1234567(ハイフンなし)"
                                tooltip="7桁の半角数字で入力してください。"
                                :validation="validateNumberEqualLen"
                                :validateArg="7"
                                :isValid.sync="
                              validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].zipCode.isValid
                            "
                                :errMsg.sync="
                              validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].zipCode.errMsg
                            "
                            />
                            <div
                                class="c-btn secondary sm"
                                @click="searchAddressHandler(transshipment.addressInfo)"
                            >
                              住所自動入力
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- /.c-inputWrap -->
                      <div class="c-inputWrap">
                        <div class="c-inputWrap__label">
                          <label>都道府県</label>
                        </div>
                        <div class="c-inputWrap__items">
                          <div>
                            <Selectbox
                                class=""
                                name="prefecture"
                                :value.sync="transshipment.addressInfo.selectedPrefectureTemp"
                                :validation="validateJustBlank"
                                :isValid.sync="
                              validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].prefecture.isValid
                            "
                                :errMsg.sync="
                              validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].prefecture.errMsg
                            "
                            >
                              <option
                                  v-for="prefecture in prefectures"
                                  :key="prefecture.id"
                                  :value="prefecture.name"
                              >
                                {{ prefecture.name }}
                              </option>
                            </Selectbox>
                          </div>
                        </div>
                      </div>
                      <!-- /.c-inputWrap -->
                      <div class="c-inputWrap">
                        <div class="c-inputWrap__label">
                          <label>市町村</label>
                        </div>
                        <div class="c-inputWrap__items">
                          <div>
                            <InputText
                                :value.sync="transshipment.addressInfo.address"
                                inputType="text"
                                placeholder="市町村"
                                tooltip="100文字以内で入力してください"
                                :validation="validateMaxLen"
                                :validateArg="100"
                                :isValid.sync="
                              validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].address.isValid
                            "
                                :errMsg.sync="
                              validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].address.errMsg
                            "
                            />
                          </div>
                        </div>
                      </div>
                      <!-- /.c-inputWrap -->
                      <div class="c-inputWrap">
                        <div class="c-inputWrap__label">
                          <label>番地建物名号室</label>
                        </div>
                        <div class="c-inputWrap__items">
                          <div>
                            <InputText
                                :value.sync="transshipment.addressInfo.buildingName"
                                inputType="text"
                                placeholder="番地建物名号室"
                                tooltip="100文字以内で入力してください"
                                :validation="validateMaxLen"
                                :validateArg="100"
                                :isValid.sync="
                              validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].building.isValid
                            "
                                :errMsg.sync="
                              validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].building.errMsg
                            "
                            />
                          </div>
                        </div>
                      </div>
                      <!-- /.c-inputWrap -->
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label required">
                      <label>電話番号</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <InputText
                          :value.sync="transshipment.tel"
                          inputType="number"
                          placeholder="電話番号"
                          tooltip="半角数値で入力してください"
                          :validation="validateNumberMaxLen"
                          :validateArg="15"
                          :isValid.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].tel.isValid
                      "
                          :errMsg.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].tel.errMsg
                      "
                      />
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>FAX番号</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <InputText
                          :value.sync="transshipment.fax"
                          inputType="number"
                          placeholder="FAX番号"
                          tooltip="半角数値で入力してください"
                          :validation="validateNumberMaxLen"
                          :validateArg="15"
                          :isValid.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].fax.isValid
                      "
                          :errMsg.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].fax.errMsg
                      "
                      />
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label required">
                      <label>JWNETID(加入者番号)</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <InputText
                          :value.sync="transshipment.jwnetContractNo"
                          inputType="text"
                          placeholder="JWNETID(加入者番号)"
                          tooltip="7桁の半角英数字で入力してください"
                          :validation="validateMaxLen"
                          :validateArg="7"
                          :isValid.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].jwnetContractNo.isValid
                      "
                          :errMsg.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].jwnetContractNo.errMsg
                      "
                      />
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label required">
                      <label>事業場コード(積保)</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <InputText
                          :value.sync="transshipment.transshipmentStoreCode"
                          inputType="number"
                          placeholder="事業場コード(積保)"
                          tooltip="最大3文字の半角数値で入力してください"
                          :validation="validateNumberMaxLen"
                          :validateArg="3"
                          :isValid.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].transshipmentStoreCode.isValid
                      "
                          :errMsg.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].transshipmentStoreCode.errMsg
                      "
                      />
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>面積</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <InputText
                          :value.sync="transshipment.area"
                          inputType="number"
                          placeholder="単位はm2"
                          tooltip="半角数値で入力してください"
                          :validation="validateNumberOrFloat"
                          :isValid.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].area.isValid
                      "
                          :errMsg.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].area.errMsg
                      "
                      />
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>保管上限</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <InputText
                          :value.sync="transshipment.storageUpperLimit"
                          inputType="number"
                          placeholder="単位はm3"
                          tooltip="半角数値で入力してください"
                          :validation="validateNumberOrFloat"
                          :isValid.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].storageUpperLimit.isValid
                      "
                          :errMsg.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].storageUpperLimit.errMsg
                      "
                      />
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>積み上げ高さ</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <InputText
                          :value.sync="transshipment.height"
                          inputType="number"
                          placeholder="単位はm"
                          tooltip="半角数値で入力してください"
                          :validation="validateNumberOrFloat"
                          :isValid.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].height.isValid
                      "
                          :errMsg.sync="
                        validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].height.errMsg
                      "
                      />
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label required">
                      <label>積替できる廃棄物種類</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div class="c-modal__body__list">
                        <div
                            class="c-checkbox"
                            v-for="(jwnet, jwnetIndex) in licence.targetJwnetItemList"
                            :key="'licence-' + licenceIndex + '-transshipment-' + transshipmentIndex + '-jwnet-' + jwnetIndex"
                        >
                          <input
                              :id="'licence-' + licenceIndex + '-transshipment-' + transshipmentIndex + '-jwnet-' + jwnetIndex"
                              type="checkbox"
                              :value="jwnet"
                              v-model="transshipment.transshipmentJwnetItemList"
                          />
                          <label
                              class="c-checkbox__label"
                              :for="'licence-' + licenceIndex + '-transshipment-' + transshipmentIndex + '-jwnet-' + jwnetIndex"
                          >
                            <span class="c-checkbox__box"></span>
                            {{ jwnet.name }}
                          </label>
                        </div>
                      </div>
                      <p v-if="validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].transshipmentJwnetItemList.errMsg" class="c-input__errMsg">
                        {{ validate.collectLicence[licenceIndex].transshipment[transshipmentIndex].transshipmentJwnetItemList.errMsg }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="l-each sm l-box">
                  <button
                      class="c-btn secondary func add"
                      @click="addTransshipmentHandler(licenceIndex)"
                      :disabled="!form.collectLicenceCreateList[licenceIndex].isTransshipment"
                  >
                    積替保管場所を追加
                  </button>
                </div>
              </div>
            </template>
          </div>
          <div class="l-each sm l-box">
            <div class="c-btn secondary func add" @click="addCollectLicence">
              許可証情報を追加
            </div>
          </div>
        </template>
        <!-- /.l-contents -->
      </div>

      <div class="l-btns l-container">
        <div class="left">
          <router-link to="/operate" class="c-btn secondary small back">一覧へ戻る</router-link>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="checkRequiredHandler">次へ</div>
        </div>
      </div>
      <!-- /.l-container -->
    </main>

    <!-- 対象廃棄物種類設定のモーダル -->
    <JwnetItemSettingModal
        ref="jwnetItemSetting"
        :isShow.sync="isJwnetItemSettingModalShow"
        :jwnetItemLicences="jwnetItemLicences"
        @setSelectedJwnetItems="setSelectedJwnetItems"
    />

    <!-- 削除確認モーダル -->
    <div class="c-modal" v-show="isDisplayDeleteModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div v-if="deleteTransshipIndex" class="c-modal__body">
          積替保管情報を削除します。<br />
          よろしいですか？
        </div>
        <div v-else class="c-modal__body">
          許可証情報を削除します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div class="c-btn secondary small" @click="closeDeleteModal">
            戻る
          </div>
          <div v-if="deleteTransshipIndex" class="c-btn primary small" @click="deleteTransshipmentHandler">
            はい
          </div>
          <div v-else class="c-btn primary small" @click="deleteLicenceHandler">
            はい
          </div>
        </div>
      </div>
    </div>

    <!-- 次へ確認モーダル -->
    <div class="c-modal" v-show="isDisplayNextModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          ここまでの内容を保存し、次の入力に進みます。<br />
          よろしいですか？
          <div class="c-modal__body__alert">
            <span v-if="duplicatedNameInfoList.length">
              <br />
              下記の名称はすでに存在しています。このまま進めてよろしいですか？
              <br />
              <br />
            </span>
            <div
                v-for="(d, index) in duplicatedNameInfoList"
                :key="index"
            >
              <span :for="index">
                {{ duplicationNameCheckTarget.filter(it => it.targetId === d.duplicationNameCheckTargetId)[0].displayName }}: {{ d.name }}
              </span>
            </div>
          </div>
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="closeConfirmModalHandler">戻る</div>
          <div class="c-btn primary small" @click="registerHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <AddressSelectModal
      :isShow.sync="isAddressSelectModalShow"
      :addresses="addressResults"
      @selectAddress="selectAddressHandler"
    />
    <AppFooter />
  </div>
</template>

<script>
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import InputDate from "@/components/parts/input/InputDate";
import validation from "@/mixin/validation";
import AddressSelectModal from "../components/AddressSelectModal.vue";
import formValue from "../mixins/formValue";
import callApi from "../mixins/callApi";
import addressSearch from "../mixins/form/addressSearch"; // 住所検索処理
import formValidation from "../mixins/form/formValidation";
import axios from "axios";
import {API_URL, DOMAIN, DUPLICATION_CHECK} from "@/const";
import common from "@/mixin/common";
import JwnetItemSettingModal from "../components/JwnetItemSettingModal";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "collector-step1-input",
  components: {
    ModalFooterButtons,
    InputText,
    Selectbox,
    InputDate,
    AddressSelectModal,
    JwnetItemSettingModal,
  },
  mixins: [
    validation,
    formValue,
    callApi,
    addressSearch,
    formValidation,
    common,
  ],
  data() {
    return {
      isEdit: false,
      errMsgs: [],
      isJwnetItemSettingModalShow: false,
      targetLicenceIndex: null,
      isDisplayDeleteModal: false,
      deleteTransshipIndex: null,
      deleteCollectLicenceIndex: null,
      isDisplayNextModal: false,
      municipalityList: [],
      postData: null, // this.formをフォーマットして最終的にpostするデータ
      processing: false,
      wasteTypeList: [
        {
          label: "産業廃棄物",
          id: 1,
        },
        {
          label: "一般廃棄物",
          id: 2,
        },
      ],
      wasteType: DOMAIN.WASTE_TYPE,
      duplicationNameCheckTarget: DUPLICATION_CHECK.NAME,
    };
  },
  methods: {
    // 許可証を追加
    addCollectLicence() {
      this.form.collectLicenceCreateList
          .push(JSON.parse(JSON.stringify(this.dummyForm.collectLicence)));
      this.validate.collectLicence
          .push(JSON.parse(JSON.stringify(this.dummyValidate.collectLicence)));
    },

    // 積替保管場所を追加
    addTransshipmentHandler(index) {
      this.form.collectLicenceCreateList[index].transshipmentCreateList
          .push(JSON.parse(JSON.stringify(this.dummyForm.transshipment)));
      this.validate.collectLicence[index].transshipment
          .push(JSON.parse(JSON.stringify(this.dummyValidate.transshipment)));
    },

    // 許可証の削除ボタン
    clickDeleteLicenceButton(index) {
      this.deleteCollectLicenceIndex = index;
      this.isDisplayDeleteModal = true;
    },

    // 積替保管場所の削除ボタン
    clickDeleteTransshipmentButton(index, transshipmentIndex) {
      this.deleteCollectLicenceIndex = index;
      this.deleteTransshipIndex = transshipmentIndex;
      this.isDisplayDeleteModal = true;
    },

    // 許可証モーダルの削除ボタン押下時
    deleteLicenceHandler() {
      this.form.collectLicenceCreateList.splice(this.deleteCollectLicenceIndex, 1);
      this.validate.collectLicence.splice(this.deleteCollectLicenceIndex, 1);
      this.closeDeleteModal();
    },

    // 積替保管場所モーダルの削除ボタン押下時
    deleteTransshipmentHandler() {
      this.form.collectLicenceCreateList[this.deleteCollectLicenceIndex].transshipmentCreateList.splice(this.deleteTransshipIndex, 1);
      this.validate.collectLicence[this.deleteCollectLicenceIndex].transshipment.splice(this.deleteTransshipIndex, 1);
      this.closeDeleteModal();
    },

    // 削除モーダルを閉じる
    closeDeleteModal() {
      this.deleteCollectLicenceIndex = null;
      this.deleteTransshipIndex = null;
      this.isDisplayDeleteModal = false;
    },

    // 許可番号フォーカスアウト
    licenceNoBlurHandler(index) {
      const target = this.form.collectLicenceCreateList[index];
      axios
          .get(API_URL.LICENCE.MUNICIPALITY + `?headNo=${target.licenceNo.substr(0, 3)}`)
          .then((res) => {
            this.municipalityList.push({
              licenceNo: target.licenceNo,
              municipality: res.data.name,
            })
          })
          .catch((err) => {
            console.log(err);
          });
    },

    // 許可証アップロード
    uploadPdf(e, index) {
      if (!e.target.files[0]) return;
      const file = e.target.files[0] || e.dataTransfer.files[0];
      this.uploadApi(file).then((path) => {
        this.form.collectLicenceCreateList[index].licenceFilePath = path;
        this.form.collectLicenceCreateList[index].licenceFileName = file.name;
      });
    },

    // 対象廃棄物種類設定モーダル開く
    showJwnetItemsModalHandler(licenceIndex) {
      this.isJwnetItemSettingModalShow = true;
      this.targetLicenceIndex = licenceIndex;
      this.$refs.jwnetItemSetting.setSelectedJwnetItems(this.form.collectLicenceCreateList[licenceIndex].targetJwnetItemList);
    },

    // 対象廃棄物種類設定モーダルを設定したときに対象formデータに選択アイテムを挿入
    setSelectedJwnetItems(selectedItems) {
      let targetForm = this.form.collectLicenceCreateList[this.targetLicenceIndex];
      targetForm.targetJwnetItemList = [];
      selectedItems.map((item) => {
        targetForm.targetJwnetItemList = [
          ...targetForm.targetJwnetItemList,
          { id: item.id, name: item.name },
        ];
      });
      targetForm.transshipmentCreateList.forEach((transshipment) => {
        transshipment.transshipmentJwnetItemList = transshipment.transshipmentJwnetItemList.filter(item => {
          return targetForm.targetJwnetItemList.find(newItem => newItem.id === item.id && newItem.name === item.name);
        });
      });
      this.validate.collectLicence[this.targetLicenceIndex].targetJwnetItemList.errMsg = "";
      this.targetLicenceIndex = null;
    },

    postForm() {
      this.errMsgs = [];
      // 住所の全角変換
      this.postData.addressInfo.address = this.toFullWidthStringForAddressInfo(this.postData.addressInfo.address);
      this.postData.addressInfo.buildingName = this.toFullWidthStringForAddressInfo(this.postData.addressInfo.buildingName);
      if (this.postData.isLicence) {
        this.postData.collectLicenceCreateList.forEach(
          (cl) => {
            if (cl.isTransshipment) {
              cl.transshipmentCreateList.forEach(
                (trans) => {
                  trans.addressInfo.address = this.toFullWidthStringForAddressInfo(trans.addressInfo.address);
                  trans.addressInfo.buildingName = this.toFullWidthStringForAddressInfo(trans.addressInfo.buildingName);
                }
              )
            }
          }
        )
      }
      this.processing = true; // 2重submit防止
      // アカウント名の全角変換
      this.postData.staffName = this.toFullWidthString(this.postData.staffName);

      let collectLicenceCreateList = [];
      let commonWasteCollectLicenceCreateList = [];

      if (this.form.isLicence === true) {
        this.postData.collectLicenceCreateList.forEach(licenceInfo => {
          // 産業廃棄物の許可証
          if (licenceInfo.wasteTypeId === 1) {
            collectLicenceCreateList.push({
              licenceNo: licenceInfo.licenceNo,
              licenceExpiredDate: licenceInfo.licenceExpiredDate,
              licenceFilePath: licenceInfo.licenceFilePath,
              licenceFileName: licenceInfo.licenceFileName,
              isExcellent: licenceInfo.isExcellent,
              targetJwnetItemList: licenceInfo.targetJwnetItemList,
              isTransshipment: licenceInfo.isTransshipment,
              transshipmentCreateList: licenceInfo.transshipmentCreateList,
            })
          } else {
            // 一般廃棄物の許可証
            commonWasteCollectLicenceCreateList.push({
              licenceNo: licenceInfo.licenceNo,
              licenceExpiredDate: licenceInfo.licenceExpiredDate,
              cityName: licenceInfo.commonCityName,
              licenceFilePath: licenceInfo.licenceFilePath,
              licenceFileName: licenceInfo.licenceFileName,
              isExcellent: licenceInfo.isExcellent,
              targetJwnetItemList: licenceInfo.targetJwnetItemList,
            })
          }
        });
      }

      // 一般廃棄物運搬許可証登録リストと運搬許可証登録リストを再設定
      this.postData.collectLicenceCreateList = collectLicenceCreateList;
      this.postData.commonWasteCollectLicenceCreateList = commonWasteCollectLicenceCreateList;
      axios.post(API_URL.COLLECTOR.POST, this.postData)
          .then((res) => {
            // ステップ２へ
            this.$router.push(`/collector/step2/input?collectorId=${res.data.collectorCompanyId}`);
          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            this.processing = false; // 2重submit解除
            this.isDisplayNextModal = false;
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
    },

    /**
     * formデータをフォーマット
     * 都道府県をIDに変換
     * フォーマットされたデータを返す(Promise)
     */
    formatPostData() {
      return new Promise((resolve) => {
        let newData = JSON.parse(JSON.stringify(this.form)); // deep copy
        if (!newData.fax) delete newData.fax;
        if (!newData.isLicence) delete newData.collectLicenceCreateList; // 2021.09.15 許可証の任意対応

        this.prefectures.map((prefecture) => {
          if (prefecture.name === newData.addressInfo.selectedPrefectureTemp) {
            newData.addressInfo.prefecturesId = prefecture.id;
          }

          if (newData.collectLicenceCreateList) {
            for (let collectLicence of newData.collectLicenceCreateList) {
              // 積替保管無しの場合に、空の積替え保管情報を削除
              if (!collectLicence.isTransshipment) {
                delete collectLicence.transshipmentCreateList;
              } else {
                for (let transshipment of collectLicence.transshipmentCreateList) {
                  if (prefecture.name === transshipment.addressInfo.selectedPrefectureTemp) {
                    transshipment.addressInfo.prefecturesId = prefecture.id;
                  }
                }
              }
            }
          }
        });

        resolve(newData);
      });
    },

    // 一般廃棄物運搬許可証登録リストと運搬許可証登録リストを分ける
    formatPostDataLicenceInfo() {
      let collectLicenceCreateList = [];
      let commonWasteCollectLicenceCreateList = [];
      this.form.collectLicenceCreateList.forEach(licenceInfo => {
        // 産業廃棄物の許可証
        if (licenceInfo.wasteTypeId === 1) {
          collectLicenceCreateList.push({
            licenceNo: licenceInfo.licenceNo,
            licenceExpiredDate: licenceInfo.licenceExpiredDate,
            licenceFilePath: licenceInfo.licenceFilePath,
            licenceFileName: licenceInfo.licenceFileName,
            isExcellent: licenceInfo.isExcellent,
            targetJwnetItemList: licenceInfo.targetJwnetItemList,
            isTransshipment: licenceInfo.isTransshipment,
            transshipmentCreateList: licenceInfo.transshipmentCreateList,
          })
        } else {
          // 一般廃棄物の許可証
          commonWasteCollectLicenceCreateList.push({
            licenceNo: licenceInfo.licenceNo,
            licenceExpiredDate: licenceInfo.licenceExpiredDate,
            cityName: licenceInfo.commonCityName,
            licenceFilePath: licenceInfo.licenceFilePath,
            licenceFileName: licenceInfo.licenceFileName,
            isExcellent: licenceInfo.isExcellent,
            targetJwnetItemList: licenceInfo.targetJwnetItemList,
          })
        }
      }
      );

      // 一般廃棄物運搬許可証登録リストと運搬許可証登録リストを再設定
      this.form.collectLicenceCreateList = collectLicenceCreateList;
      this.form.commonWasteCollectLicenceCreateList = commonWasteCollectLicenceCreateList;
    },

    // 次へ押下
    async checkRequiredHandler() {
      this.errMsgs = [];
      this.duplicatedNameInfoList = [];
      this.duplicationNameCheckInfoList = [];

      const isValid = await this.runValidate();
      if (!isValid) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      this.duplicationNameCheckInfoList.push({
        duplicationNameCheckTargetId: this.duplicationNameCheckTarget[4].targetId,
        name: this.form.companyName
      });
      this.checkDuplicationName(this.duplicationNameCheckInfoList)

      // 都道府県をIDに変える、不要なプロパティ消す
      this.formatPostData().then((res) => {
        this.isDisplayNextModal = true;
        this.postData = res;
      });
    },

    // モーダル内戻る押下
    closeConfirmModalHandler() {
      this.isDisplayNextModal = false;
      this.postData = null;
    },

    // モーダル内登録押下
    registerHandler() {
      this.postForm();
    },
  },

  mounted() {
    this.getPrefecturesApi();
    this.getLegalPersonalityTypesApi();
    this.getJwnetItemLicencesApi();
  },
};
</script>
