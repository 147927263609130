export default {
  data() {
    return {
      form: {
        businessCode: null,
        legalPersonalityTypeId: null,
        companyName: "",
        addressInfo: {
          zipCode: "",
          prefecturesId: null,
          address: "",
          buildingName: "",
        },
        tel: null,
        fax: null,
        staffName: "",
        staffMail: "",
        isLicence: true,
        collectLicenceCreateList: [
          {
            wasteTypeId: 1,
            commonCityName: null,
            licenceNo: "",
            licenceExpiredDate: "",
            licenceFilePath: null,
            licenceFileName: null,
            isExcellent: false,
            targetJwnetItemList: [],
            isTransshipment: false,
            transshipmentCreateList: [
              {
                name: "",
                addressInfo: {
                  zipCode: "",
                  prefecturesId: null,
                  address: "",
                  buildingName: "",
                },
                tel: null,
                fax: null,
                jwnetContractNo: "",
                transshipmentStoreCode: "",
                area: null,
                storageUpperLimit: null,
                height: null,
                transshipmentJwnetItemList: [],
                selectedPrefectureTemp: "",
              }
            ], // 積替保管場所
          }
        ],
        commonWasteCollectLicenceCreateList: [],
      },
      dummyForm: {
        collectLicence: {
          wasteTypeId: 1,
          licenceNo: "",
          licenceExpiredDate: "",
          licenceFilePath: null,
          licenceFileName: null,
          isExcellent: false,
          targetJwnetItemList: [],
          isTransshipment: false,
          transshipmentCreateList: [
            {
              name: "",
              addressInfo: {
                zipCode: "",
                prefecturesId: null,
                address: "",
                buildingName: "",
              },
              tel: null,
              fax: null,
              jwnetContractNo: "",
              transshipmentStoreCode: "",
              area: null,
              storageUpperLimit: null,
              height: null,
              transshipmentJwnetItemList: [],
              selectedPrefectureTemp: "",
            }
          ], // 積替保管場所
        },
        transshipment: {
          name: "",
          addressInfo: {
            zipCode: "",
            prefecturesId: null,
            address: "",
            buildingName: "",
          },
          tel: null,
          fax: null,
          jwnetContractNo: "",
          transshipmentStoreCode: "",
          area: null,
          storageUpperLimit: null,
          height: null,
          transshipmentJwnetItemList: [],
          selectedPrefectureTemp: "",
        }
      },
      duplicationNameCheckInfoList: [],
    };
  },
};
